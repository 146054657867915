import _embeddedDevelopment from "./umd/embedded.development.js";
export { _embeddedDevelopment as default }; // eslint-disable-line global-require

export const HelloSign = _embeddedDevelopment.HelloSign,
      __esModule = _embeddedDevelopment.__esModule,
      TemplateTag = _embeddedDevelopment.TemplateTag,
      trimResultTransformer = _embeddedDevelopment.trimResultTransformer,
      stripIndentTransformer = _embeddedDevelopment.stripIndentTransformer,
      replaceResultTransformer = _embeddedDevelopment.replaceResultTransformer,
      replaceSubstitutionTransformer = _embeddedDevelopment.replaceSubstitutionTransformer,
      replaceStringTransformer = _embeddedDevelopment.replaceStringTransformer,
      inlineArrayTransformer = _embeddedDevelopment.inlineArrayTransformer,
      splitStringTransformer = _embeddedDevelopment.splitStringTransformer,
      removeNonPrintingValuesTransformer = _embeddedDevelopment.removeNonPrintingValuesTransformer,
      commaLists = _embeddedDevelopment.commaLists,
      commaListsAnd = _embeddedDevelopment.commaListsAnd,
      commaListsOr = _embeddedDevelopment.commaListsOr,
      html = _embeddedDevelopment.html,
      codeBlock = _embeddedDevelopment.codeBlock,
      source = _embeddedDevelopment.source,
      safeHtml = _embeddedDevelopment.safeHtml,
      oneLine = _embeddedDevelopment.oneLine,
      oneLineTrim = _embeddedDevelopment.oneLineTrim,
      oneLineCommaLists = _embeddedDevelopment.oneLineCommaLists,
      oneLineCommaListsOr = _embeddedDevelopment.oneLineCommaListsOr,
      oneLineCommaListsAnd = _embeddedDevelopment.oneLineCommaListsAnd,
      inlineLists = _embeddedDevelopment.inlineLists,
      oneLineInlineLists = _embeddedDevelopment.oneLineInlineLists,
      stripIndent = _embeddedDevelopment.stripIndent,
      stripIndents = _embeddedDevelopment.stripIndents,
      KEY = _embeddedDevelopment.KEY,
      NEED = _embeddedDevelopment.NEED,
      fastKey = _embeddedDevelopment.fastKey,
      getWeak = _embeddedDevelopment.getWeak,
      onFreeze = _embeddedDevelopment.onFreeze,
      f = _embeddedDevelopment.f,
      set = _embeddedDevelopment.set,
      log = _embeddedDevelopment.log,
      formatArgs = _embeddedDevelopment.formatArgs,
      save = _embeddedDevelopment.save,
      load = _embeddedDevelopment.load,
      useColors = _embeddedDevelopment.useColors,
      storage = _embeddedDevelopment.storage,
      colors = _embeddedDevelopment.colors,
      TinyEmitter = _embeddedDevelopment.TinyEmitter;